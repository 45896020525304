<template>
  <div>
    <v-card class="mb-5" outlined color="white">
      <v-breadcrumbs :items="items" large></v-breadcrumbs>
    </v-card>

    <v-card outlined height="80vh" class="mt-2">
      <iframe
        :src="getPDF(pdfURL) + '#toolbar=1'"
        type="application/pdf"
        height="100%"
        width="100%"
        frameborder="0"
      ></iframe>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    pdfURL: "",
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Profil",
        disabled: true,
        href: "",
      },
      {
        text: "Tupoksi Inspektorat",
        disabled: false,
        href: "/profil/tupoksi",
      },
    ],
  }),

  mounted() {
    this.getData();
  },

  methods: {
    getPDF(value) {
      if (value) {
        return process.env.VUE_APP_API_BASE + "upload/profilGambar/" + value;
      } else {
        return (
          process.env.VUE_APP_API_BASE + "upload/profilGambar/pdf-default.pdf"
        );
      }
    },

    getData() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "profil/tupoksi")
        .then((res) => {
          this.pdfURL = res.data.data.profil_file;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
